import React from 'react';
import { FLOW_BUSINESS_BENEFITS } from 'views/flow-cadence/constants';
import { BusinessBenefitsSection } from 'modules/business-benefits-section';

export const FlowBusinessBenefitsSection = () => {
    return (
        <BusinessBenefitsSection
            title="flow-cadence.businessBenefits"
            description="flow-cadence.flowBlockchainSolves"
            data={FLOW_BUSINESS_BENEFITS}
        />
    );
};
