import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CodeConsultingSection } from 'views/flow-cadence/code-consulting-section';
import {
    LATEST_RELATED_ARTICLES_SETTINGS,
    PERKS_LIST,
} from 'views/flow-cadence/constants';
import { ContactWithExpertSection } from 'views/flow-cadence/contact-with-expert-section';
import { FlowBusinessBenefitsSection } from 'views/flow-cadence/flow-business-benefits-section';
import { HireDevelopersSection } from 'views/flow-cadence/hire-developers-section';
import { ObjectiveAdvantagesSection } from 'views/flow-cadence/objective-advantages-section';
import { CONSTANTS } from 'constants/styles/constants';
import { FooterSection } from 'modules/footer-section';
import { WorkWithMRSection } from 'modules/technologies/work-with-mr-section';
import { array } from 'prop-types';

const Container = styled.div`
    padding-top: ${CONSTANTS.MARGIN.PAGE};
`;

const Props = {
    faqData: array.isRequired,
};

export const FlowCadenceView = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <CodeConsultingSection />
            <ObjectiveAdvantagesSection />
            <HireDevelopersSection />
            <FlowBusinessBenefitsSection />
            <WorkWithMRSection
                title="flow-cadence.workWithMobileReality.title"
                firstRowDescriptions={[
                    'flow-cadence.workWithMobileReality.atMobileReality',
                    'flow-cadence.workWithMobileReality.agileDevelopment',
                ]}
                secondRowDescriptions={[
                    'flow-cadence.workWithMobileReality.collaborativeApproach',
                ]}
                perksData={PERKS_LIST}
                firstRowAlt={formatMessage({
                    id: 'flow-cadence.workWithMobileReality.firstRowAlt',
                })}
                secondRowAlt={formatMessage({
                    id: 'flow-cadence.workWithMobileReality.secondRowAlt',
                })}
            />
            <ContactWithExpertSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </Container>
    );
};

FlowCadenceView.propTypes = Props;
