import React from 'react';
import { MainSectionTemplate } from 'components/main-section-template';
import { gaTrackedEvents } from 'constants/ga-tracked-events';

export const CodeConsultingSection = () => {
    return (
        <MainSectionTemplate
            title="flow-cadence.flowCadence"
            subTitle="flow-cadence.lookingForFlowDevelopmentServices"
            googleAnalytics={gaTrackedEvents.FLOW_CADENCE.CTA.ESTIMATE_PROJECT}
        />
    );
};
