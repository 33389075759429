import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import BlockChainSvg from 'svgs/blog/blockchain.svg';
import EfficientIcon from 'svgs/icons/ic-efficient.svg';
import OpportunityIcon from 'svgs/icons/ic-opportunity.svg';
import SecurityIcon from 'svgs/icons/ic-security.svg';
import CertifiedIcon from 'svgs/technologies/work-with-mr/award.svg';
import MarketIcon from 'svgs/technologies/work-with-mr/calendar-tick.svg';
import MaintainersIcon from 'svgs/technologies/work-with-mr/code.svg';
import ClientsIcon from 'svgs/technologies/work-with-mr/people.svg';

export const FLOW_BUSINESS_BENEFITS = [
    {
        icon: <EfficientIcon />,
        text: 'flow-cadence.verifyAUthenticity',
        description: 'flow-cadence.distributedRegistry',
    },
    {
        icon: <SecurityIcon />,
        text: 'flow-cadence.financialSecurity',
        description: 'flow-cadence.flowEnhancesTheSecurity',
    },
    {
        icon: <OpportunityIcon />,
        text: 'flow-cadence.disputeResolution',
        description: 'flow-cadence.theImmutableNature',
    },
];

export const PERKS_LIST = [
    {
        title: 'flow-cadence.workWithMobileReality.onTheMarket',
        icon: <MarketIcon />,
    },
    {
        title: 'flow-cadence.workWithMobileReality.certifiedDevelopers',
        icon: <CertifiedIcon />,
    },
    {
        title: 'flow-cadence.workWithMobileReality.ownersAndMaintainers',
        icon: <MaintainersIcon />,
    },
    {
        title: 'flow-cadence.workWithMobileReality.experiencedInWorking',
        icon: <ClientsIcon />,
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    tags: [BLOG_POST_TAGS.BLOCKCHAIN_WEB3],
    button: {
        to: `${PATHS.BLOG}/${PATHS.BLOCKCHAIN}`,
        label: 'flow-cadence.footer.button',
    },
    categoryBanner: {
        title: 'flow-cadence.footer.title',
        description: 'flow-cadence.footer.description',
        svg: BlockChainSvg,
    },
};
